
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import "@fortawesome/fontawesome-free/css/all.min.css";
import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import t, { codeSurferTheme } from "./theme";
import { ArrowRight } from "./ArrowRight";
import { Centered } from "./Centered";
import { Chart } from "./Chart";
import { Col } from "./Col";
import { Container } from "./Container";
import { FullScreenImg } from "./FullScreenImg";
import { Img } from "./Img";
import { Please } from "./Please";
import { ProgressBar } from "./ProgressBar";
import { RoundedBox } from "./RoundedBox";
import { StepChart } from "./StepChart";
import { Thankyou } from "./Thankyou";
import { TitlePage } from "./TitlePage";
import { TwoCols } from "./TwoCols";
import { WhoAmI } from "./WhoAmI";
import elephant from "./assets/elephant.jpg";
import pingu from "./assets/pingu.jpg";
import ts2365 from "./assets/ts2365.png";
import "./styles.css";
export const theme = t;
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Head = makeShortcode("Head");
const Footer = makeShortcode("Footer");
const Steps = makeShortcode("Steps");
const Notes = makeShortcode("Notes");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Head mdxType="Head">
  <title>ReactNative + JustWatch</title>
    </Head>
    <Footer mdxType="Footer">
      <ProgressBar mdxType="ProgressBar" />
    </Footer>
    <TitlePage mdxType="TitlePage" />
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`Wat`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">{`React and React Native ⚛️`}</li>
          <li parentName="ul">{`More about React Native than you ever wanted to know`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`jw-app-mobile`}</inlineCode>{` under the hood`}</li>
          <li parentName="ul">{`Where to find more`}</li>
          <li parentName="ul">{`🍕 + Q`}{`&`}{`A`}</li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`React Native`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`A framework to build `}<u>{`native`}</u>{` apps in React`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Android, iOS, Web, TVs, Windows, OSX, Oculus ( `}<Img src={pingu} height={50} inline mdxType="Img" />{` )`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Learn once, write everywhere`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Write once, wait for Gradle and Xcode to build forever 😬`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`How does it work`}</h1>
      <StepChart mdxType="StepChart">
        <RoundedBox mdxType="RoundedBox">
  
          <p>React<br />(JS)</p>
        </RoundedBox>
        <ArrowRight mdxType="ArrowRight">JSON</ArrowRight>
        <RoundedBox borderGradient={{
          left: "#61dafb",
          right: "#43db86"
        }} mdxType="RoundedBox">
  Bridge
        </RoundedBox>
        <ArrowRight color="#43db86" mdxType="ArrowRight">????</ArrowRight>
        <RoundedBox borderColor="#43db86" mdxType="RoundedBox">Native Views</RoundedBox>
      </StepChart>
    </Container>
    <hr></hr>
    <CodeSurfer theme={codeSurferTheme} mdxType="CodeSurfer">
      {
        /* prettier-ignore-start */
      }
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "title=\"React\" subtitle=\"* not shown here: 976MB of node_modules\"",
          "title": "\"React\"",
          "subtitle": "\"*",
          "not": true,
          "shown": true,
          "here:": true,
          "976MB": true,
          "of": true,
          "node_modules\"": true
        }}>{`import React from "react";

export const HelloSomeone = (props) => (
  <div>
    Hello, {props.who}!
  </div>
);
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "title=\"React\"",
          "title": "\"React\""
        }}>{`import React from "react";
import { HelloSomeone } from "./HelloSomeone";

export const MyApp = () => (
  <HelloSomeone who="world" />
);
`}</code></pre>
      {
        /* prettier-ignore-end */
      }
    </CodeSurfer>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`What is React anyway`}</h1>
      <StepChart mdxType="StepChart">
        <RoundedBox mdxType="RoundedBox">
  
JSX
        </RoundedBox>
        <ArrowRight mdxType="ArrowRight">JS</ArrowRight>
        <RoundedBox mdxType="RoundedBox">Layout tree</RoundedBox>
        <ArrowRight mdxType="ArrowRight">????</ArrowRight>
        <RoundedBox mdxType="RoundedBox">Rendering target</RoundedBox>
      </StepChart>
      <p>{`A diff engine for trees 🌳`}</p>
    </Container>
    <Notes mdxType="Notes">
      <p>{`You don't need to know any of this while developing a regular RN app!`}</p>
      <p>{`???? => calls that depend on the actual platform`}</p>
    </Notes>
    <hr></hr>
    <CodeSurferColumns themes={[codeSurferTheme, codeSurferTheme]} mdxType="CodeSurferColumns">
      <Step mdxType="Step">
        {
          /* prettier-ignore-start */
        }
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "title=\"React (JS)\"",
            "title": "\"React",
            "(JS)\"": true
          }}>{`import React from "react";


export const HelloSomeone = (props) => (
  <div>
    Hello, {props.who}!
  </div>
);
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "title=\"React Native\"",
            "title": "\"React",
            "Native\"": true
          }}>{`import React from "react";
import { Text, View } from "react-native";

export const HelloSomeone = (props) => (
  <View>
    <Text>Hello, {props.who}!</Text>
  </View>
);
`}</code></pre>
        {
          /* prettier-ignore-end */
        }
      </Step>
      <Step mdxType="Step">
        {
          /* prettier-ignore-start */
        }
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "5,7 title=\"React (JS)\"",
            "5,7": true,
            "title": "\"React",
            "(JS)\"": true
          }}>{`import React from "react";


export const HelloSomeone = (props) => (
  <div>
    Hello, {props.who}!
  </div>
);
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "2,5,6[5:10,30:36],7 title=\"React Native\"",
            "2,5,6[5:10,30:36],7": true,
            "title": "\"React",
            "Native\"": true
          }}>{`import React from "react";
import { Text, View } from "react-native";

export const HelloSomeone = (props) => (
  <View>
    <Text>Hello, {props.who}!</Text>
  </View>
);
`}</code></pre>
        {
          /* prettier-ignore-end */
        }
      </Step>
    </CodeSurferColumns>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`React (in browsers)`}</h1>
      <Chart mdxType="Chart">
        <RoundedBox mdxType="RoundedBox">
  
JSX
        </RoundedBox>
        <ArrowRight mdxType="ArrowRight">JS</ArrowRight>
        <RoundedBox mdxType="RoundedBox">Virtual DOM</RoundedBox>
        <ArrowRight mdxType="ArrowRight">JS</ArrowRight>
        <RoundedBox mdxType="RoundedBox">DOM</RoundedBox>
      </Chart>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`React Native`}</h1>
      <Chart mdxType="Chart">
        <RoundedBox mdxType="RoundedBox">
  
JSX
        </RoundedBox>
        <ArrowRight mdxType="ArrowRight">Bridge</ArrowRight>
        <RoundedBox borderColor="#43db86" mdxType="RoundedBox">Shadow tree</RoundedBox>
        <ArrowRight color="#43db86" mdxType="ArrowRight">Yoga</ArrowRight>
        <RoundedBox borderColor="#43db86" mdxType="RoundedBox">Native Views</RoundedBox>
      </Chart>
    </Container>
    <Notes mdxType="Notes">
      <p>{`The bridge is the same we've seen on the previous slides`}</p>
      <p>{`Yoga is a cross-platform layout engine written in C that implements FlexBox`}</p>
    </Notes>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`Under the hood:`}</h1>
      <Centered mdxType="Centered">
        <p>{`👀`}</p>
      </Centered>
    </Container>
    <Notes mdxType="Notes">
      <p>{`Hermes, Metro Bundler, Debug, API, Hooks vs setState()`}</p>
    </Notes>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`More on this`}</h1>
      <Centered fontSize="84px" mdxType="Centered">
        <a href="https://www.youtube.com/watch?v=8va9prUqjnA" target="_blank" rel="noreferrer">
  Emil Sjölander - React Native, The Native Bits
        </a>
      </Centered>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`RN Libs and Frameworks`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Navigation ⛵ (`}<a href="https://reactnavigation.org/" target="_blank" rel="noreferrer">{`react-navigation`}</a>{`)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Firebase 🔥 (`}<a href="https://rnfirebase.io/" target="_blank" rel="noreferrer">{`react-native-firebase`}</a>{`) and `}<a href="https://github.com/microsoft/react-native-code-push" target="_blank" rel="noreferrer">{`CodePush`}</a>{` 🆙`}</p>
          </li>
          <li parentName="ul">
            <a href="https://relay.dev" target="_blank" rel="noreferrer">
  Relay
            </a> 😍 (GraphQL library)
          </li>
          <li parentName="ul">
            <p parentName="li">{`Animations 🌠 (`}<a href="https://github.com/software-mansion/react-native-reanimated" target="_blank" rel="noreferrer">{`react-native-reanimated`}</a>{`) and Gestures (`}<a href="https://github.com/software-mansion/react-native-gesture-handler" target="_blank" rel="noreferrer">{`react-native-gesture-handler`}</a>{`) => don't miss `}<a href="https://www.youtube.com/c/wcandillon/playlists" target="_blank" rel="noreferrer">{`Can it be done in React Native?`}</a>{`!`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Global State Manager 🌲 (`}<a href="https://redux-toolkit.js.org/" target="_blank" rel="noreferrer">{`redux-toolkit`}</a>{`)`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`jw-app-mobile, a guided tour`}</h1>
      <Centered mdxType="Centered">
        <p>{`🗺️`}</p>
      </Centered>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`The root of all evil`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Even more than in React, `}<em parentName="p">{`re-renders`}</em>{` are 👿`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Avoid at all costs`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`No, but seriously`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Re-renders must usually cross the bridge 🌉 👋`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`When does React re-render, again?`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`When its internal state changes (🪝)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`When its parent re-renders 👨‍👦, and either:`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Its props change (`}<em parentName="p">{`referentially`}</em>{`... `}<inlineCode parentName="p">{`() => ...`}</inlineCode>{`, `}<inlineCode parentName="p">{`{}`}</inlineCode>{`❗), or`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`It's attached to a context provider, and context changed`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`More on this`}</h1>
      <Centered mdxType="Centered">
        <a href="https://www.zhenghao.io/posts/react-rerender" target="_blank" rel="noreferrer">
  A great post on re-renders in React
        </a>
      </Centered>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`Your weapons ⚔️`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`useMemo()`}</inlineCode>{` (for values), `}<inlineCode parentName="p">{`useCallback()`}</inlineCode>{` (for functions)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`use these in the parent component`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`React.memo()`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`use this in the child component`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`memo(Component, (prevProps, nextProps) => skipRender)`}</inlineCode></p>
          </li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`Life of a RN developer`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Building for RN inevitably means using many 3rd party libs`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Quality and maintainers responsiveness vary `}<strong parentName="p">{`a lot`}</strong></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Things break and you have to wait just to get your PRs approved!`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`...or `}<em parentName="p">{`do you?`}</em>{` 😏`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`The hero npm doesn't deserve`}</h1>
      <Steps mdxType="Steps">
        <p>{`Enter `}<a href="https://github.com/ds300/patch-package" target="_blank" rel="noreferrer">{`patch-package`}</a>{` 🚀`}</p>
        <p>{`Fix the bug directly inside the library's `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` folder`}</p>
        <p>{`Run `}<inlineCode parentName="p">{`npx patch-package the-library-you-fixed`}</inlineCode></p>
        <p>{`Now you have a `}<inlineCode parentName="p">{`.patch`}</inlineCode>{` file that you can commit, and even share! 🤯`}</p>
      </Steps>
    </Container>
    <Notes mdxType="Notes">
      <p>{`This is such a huge improvement over any other experience I've had (including gradle) that I wanted to discuss it separately`}</p>
    </Notes>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`A few RN Gotchas`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Always double-check that there's only `}<strong parentName="p">{`one`}</strong>{` running Metro Bundler`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Emulator not connecting => `}<inlineCode parentName="p">{`adb reverse tcp:8081 tcp:8081`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Circular dependencies do exist (declaration order does matter!)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Changes to the native side require a full app re-launch`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`yarn install`}</inlineCode>{` after checkout, `}<inlineCode parentName="p">{`pod install`}</inlineCode>{` if on iOS`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <Notes mdxType="Notes">
      <p>{`As said, there's a lot of info not being captured on the official website`}</p>
      <p>{`This is meant to be a very brief collection of the issues you're most likely to encounter`}</p>
      <p>{`The goal is to help you think "hey, I've heard about this"`}</p>
    </Notes>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`A few more RN Gotchas`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Check your 3rd party dependencies quite thoroughly`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Keep an eye on the bridge 👀`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Sometimes a `}<inlineCode parentName="p">{`flexDirection: 'row'`}</inlineCode>{` goes a long way`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <Notes mdxType="Notes">
      <p>{`You don't need to do an audit, but do check how often the repo is updated, when it was last updated, how many open/closed PRs, whether issues get ignored`}</p>
    </Notes>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`Selected topics 🔍`}</h1>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`The `}<inlineCode parentName="p">{`key`}</inlineCode>{` prop (from the main website, and `}<a href="https://kentcdodds.com/blog/understanding-reacts-key-prop" target="_blank" rel="noreferrer">{`more in-depth`}</a>{`)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`<FlatList>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<SectionList>`}</inlineCode>{`, and `}<a href="https://reactnative.dev/docs/optimizing-flatlist-configuration" target="_blank" rel="noreferrer">{`how to optimize them`}</a></p>
          </li>
          <li parentName="ul">
            <a href="https://blog.bam.tech/developer-news/measuring-and-improving-performance-on-a-react-native-app" target="_blank" rel="noreferrer">
  Measuring performance in a React Native app
            </a>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The `}<a href="https://beta.reactjs.org/" target="_blank" rel="noreferrer">{`new React documentation website`}</a>{` (in "beta", but)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Did I mention `}<a href="https://relay.dev/docs/principles-and-architecture/thinking-in-relay/" target="_blank" rel="noreferrer">{`Relay`}</a>{` 😻?`}</p>
          </li>
        </ul>
      </Steps>
    </Container>
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`🙏 pleeeeeeeeeeeeeease 🙏`}</h1>
      <p>{`Don't let this be me in August 😭`}</p>
      <Please mdxType="Please" />
    </Container>
    <hr></hr>
    <Thankyou mdxType="Thankyou" />
    <hr></hr>
    <Container mdxType="Container">
      <h1>{`Questions?`}</h1>
      <Centered fontSize="64px" mdxType="Centered">
        <p>{`Or... 🍕?`}</p>
      </Centered>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;