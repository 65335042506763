import React from "react";
import styled from "@emotion/styled";
import { useThemeUI } from "theme-ui";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const CenterAligned = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 2em;
`;

export const Please = () => {
  const { theme } = useThemeUI();
  return (
    <Wrapper theme={theme}>
      <CenterAligned>
        <iframe
          src="https://giphy.com/embed/RkMuw6XZxPqNy"
          title="Donald Glover"
          width="294"
          height="300"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </CenterAligned>
      <CenterAligned>
        <iframe
          src="https://giphy.com/embed/hdEAzYiaCjhUA"
          title="The Godfather"
          width="480"
          height="262"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </CenterAligned>
    </Wrapper>
  );
};
